import React from "react";
import "./Frenchtest.css";

import tiger from "../../img/tiger.png";
import CallToAction from "../../component/CallToAction";

function Frenchtest() {
  return (
    <div className="Frenchtest">
      <div className="Frenchtest__tigermobile">
        <img src={tiger} alt="" />
      </div>
      <div className="Frenchtest__leftblock">
        <div className="Frenchtest__leftblock__heading">
          <h1>Take the French test</h1>
        </div>
        <div className="Frenchtest__leftblock__paragraph">
          <p>Evaluate your level with our French test, for free.</p>
        </div>
        <div className="Frenchtest__leftblock__Cta">
          <a href="/Evaluate_your_french_level_Le_Cercle_Francais">
            <CallToAction label="Take the quiz" bg="#ff6b00" />
          </a>
        </div>
      </div>
      <div className="Frenchtest__rightblock">
        <img src={tiger} alt="" />
      </div>
    </div>
  );
}

export default Frenchtest;
