import React, { createContext, useState, useContext, useEffect } from 'react';

const CookieConsentContext = createContext();

export const useCookieConsent = () => useContext(CookieConsentContext);

export const CookieConsentProvider = ({ children }) => {
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowCookieConsent(true);  // Afficher le pop-up si aucun consentement n'est enregistré
    } else {
      setShowCookieConsent(false);  // Ne pas afficher le pop-up si un consentement est déjà enregistré
    }
    setIsLoading(false);  // Fin du chargement
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowCookieConsent(false);  // Cacher le pop-up après acceptation
  };

  const handleRefuse = () => {
    localStorage.setItem('cookieConsent', 'refused');
    setShowCookieConsent(false);  // Cacher le pop-up après refus
  };

  return (
    <CookieConsentContext.Provider value={{ showCookieConsent, handleAccept, handleRefuse, isLoading }}>
      {children}
    </CookieConsentContext.Provider>
  );
};
