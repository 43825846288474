// src/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, List, ListItem, AppBar, Toolbar } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import './Dashboard.css';

function Dashboard() {
  const history = useHistory();
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userSurname, setUserSurname] = useState('');
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      setUserEmail(user.email); // Set user email
      const db = getFirestore();
      const fetchUserData = async () => {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserName(userDoc.data().name); // Set user name
          setUserSurname(userDoc.data().surname); // Set user surname
        }
      };
      fetchUserData();
    } else {
      history.push('/login'); // Redirect to login if no user
    }

    // Update the current time every second
    const updateTime = () => {
      const date = new Date();
      const options = { timeZone: 'Europe/Paris', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      setCurrentTime(date.toLocaleTimeString('fr-FR', options));
    };

    const timerId = setInterval(updateTime, 1000);
    return () => clearInterval(timerId);
  }, [history]);

  const handleLogout = async () => {
    const auth = getAuth();
    await signOut(auth); // Sign out the user
    history.push('/login'); // Redirect to login page after logout
    window.location.reload(); // Refresh the page to reset the application state
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Dashboard</Typography>
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Box display="flex" flexGrow={1}>
        <Box flexGrow={1} padding={3}>
          <Typography variant="h4">Welcome to your dashboard!</Typography>
          {userEmail && <Typography variant="body1">Logged in as: {userEmail}</Typography>}
          {userName && userSurname && (
            <Typography variant="body1">
              Welcome, {userName} {userSurname}!
              <br />
              <AccessTimeIcon />
              Time in Paris: {currentTime}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Dashboard;
