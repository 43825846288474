import React from "react";
import CallToActionTiny from "../../component/CallToActionTiny";
import "./Coursecardfull.css";
import Badge from "../../component/Badge";
import CallToAction100 from "../../component/CallToAction100";

function Coursecardfull(props) {
  return (
    <div className="Ourcourses__align">
      <div className="Ourcourses__card">
        <div className="Ourcourses__card__left__block">
          <img src={props.img} alt="" />
        </div>
        <div className="Ourcourses__card__right__block">
          <div className="Ourcourses__card__right__block__top">
            <div className="Ourcourses__card__right__block__top__heading">
              <h2>{props.coursetitle}</h2>
            </div>

            <p>{props.coursedescription}</p>
          </div>
          <div className="Ourcourses__card__right__block__bottom">
            <div className="Ourcourses__card__right__block__bottom__container">
              <div className="Ourcourses__card__right__block__bottom__container__top">
                <h3>Ages</h3>
              </div>
              <div className="Ourcourses__card__right__block__bottom__container__bottom">
                <p>{props.ages}</p>
              </div>
            </div>

            <div className="Ourcourses__card__right__block__bottom__container">
              <div className="Ourcourses__card__right__block__bottom__container__top">
                <h3>Duration</h3>
              </div>
              <div className="Ourcourses__card__right__block__bottom__container__bottom">
                <p>{props.duration}</p>
              </div>
            </div>

            <div className="Ourcourses__card__right__block__bottom__container">
              <div className="Ourcourses__card__right__block__bottom__container__top">
                <h3>Sessions</h3>
              </div>
              <div className="Ourcourses__card__right__block__bottom__container__bottom">
                <p>{props.sessions}</p>
              </div>
            </div>

            <div className="Ourcourses__card__right__block__bottom__container">
              <div className="Ourcourses__card__right__block__bottom__container__top">
                <h3>Seats Left</h3>
              </div>
              <div className="Ourcourses__badge">

                <Badge label={props.participants}/>
              </div>
            </div>
            <div className="Ourcourses__card__right__block__bottom__container">
              <div className="Ourcourses__card__right__block__bottom__container__top">
                <h3>Price</h3>
              </div>
              <div className="Ourcourses__card__right__block__bottom__container__bottom">
                <p>{props.price}</p>
              </div>
            </div>

            <div className="Ourcourses__card__right__block__bottom__container__button">
              <CallToAction100 label="View" bg="#ff6b00" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Coursecardfull;
