import React from "react";
import Headerhome from "../Sections/HomeComponents/Headerhome";
import Footer from "../Sections/Footer";

function Cancellation() {
  return (
    <div className="Cancellation">
      <Headerhome />
      <div className="Margin__global">
        <h2>Cancellation and Refund Policy</h2>

        <p>Last Updated: October 21, 2024</p>

        <p>
          At Le Cercle Français, we understand that sometimes plans change.
          Here’s our policy on cancellations and refunds to ensure a smooth
          experience for everyone. 😊
        </p>

        <h2>1. Cancellation by You</h2>

        <p>
          <strong>1.1</strong> You can cancel your enrollment within 7 days of
          registration for a full refund, provided that the course has not
          started.
        </p>

        <p>
          <strong>1.2</strong> To cancel your enrollment, please contact us at{" "}
          <a href="mailto:contact@lecerclefrancais.in">
            contact@lecerclefrancais.in
          </a>{" "}
          with your registration details.
        </p>

        <p>
          <strong>1.3</strong> Please note that no refunds will be provided
          after the course has commenced.
        </p>

        <h2>2. Refunds</h2>

        <p>
          <strong>2.1</strong> If you cancel within the 7-day period and the
          course has not started, you are eligible for a full refund.
        </p>

        <p>
          <strong>2.2</strong> Refunds will be processed within 14 working days
          from the date your cancellation request is approved.
        </p>

        <p>
          <strong>2.3</strong> Refunds will be issued to the original payment
          method used during registration.
        </p>

        <h2>3. Cancellation by Us</h2>

        <p>
          <strong>3.1</strong> We reserve the right to cancel any course due to
          unforeseen circumstances, such as insufficient enrollment or
          instructor availability.
        </p>

        <p>
          <strong>3.2</strong> In the event that we cancel a course, you will be
          notified via email and offered a full refund.
        </p>

        <p>
          <strong>3.3</strong> Refunds for courses canceled by us will be
          processed within 14 working days.
        </p>

        <h2>4. How to Request a Cancellation or Refund</h2>

        <p>
          <strong>4.1</strong> To request a cancellation or refund, please
          contact us at{" "}
          <a href="mailto:contact@lecerclefrancais.in">
            contact@lecerclefrancais.in
          </a>
          . Include your name, course details, and reason for cancellation.
        </p>

        <p>
          <strong>4.2</strong> Our team will review your request and respond
          within 3 working days.
        </p>

        <h2>5. Important Notes</h2>

        <p>
          <strong>5.1</strong> Refunds are only available for courses that have
          not commenced.
        </p>

        <p>
          <strong>5.2</strong> Partial refunds are not available.
        </p>

        <p>
          <strong>5.3</strong> If you paid for the course using a credit card,
          the refund will be credited back to your card.
        </p>

        <p>
          We strive to provide the best possible experience for our students. If
          you have any questions or concerns about our cancellation and refund
          policy, please feel free to reach out to us at{" "}
          <a href="mailto:contact@lecerclefrancais.in">
            contact@lecerclefrancais.in
          </a>
          . Thank you for choosing Le Cercle Français! 🇫🇷
        </p>
      </div>
      <div className="h64"></div>
      <Footer />
    </div>
  );
}

export default Cancellation;
