import React from "react";
import { useCookieConsent } from "../CookieConsentContext"; // Adjust the import path as needed
import "./CookiesPopup.css";

function CookiesPopup() {
  const { showCookieConsent, handleAccept, handleRefuse, isLoading } =
    useCookieConsent();

  // The component will not render if 'isLoading' is true or 'showCookieConsent' is false
  if (!showCookieConsent || isLoading) return null;

  return (
    <div className="Cookie__popup">
      <div className="Cookie__popup__padding">
        <div className="Cookie__popup__blocks">

          <div className="Cookie__popup__leftblock">
            <b>
              <p>🍪 Cookies Consent </p>
            </b>
            <p>
              When you visit our site, cookies are placed on your device. Visit our{" "}
              <a href="/cookies">
                <span className="underline__cookies">Cookie Policy</span>
              </a>
              {"  "}for more information.
            </p>
          </div>
          <div className="Cookie__popup__rightblock">
            <div className="cookie-button_refuse" onClick={handleRefuse}>
              <p>Decline</p>
            </div>
            <div className="cookie-button_accept" onClick={handleAccept}>
              <p>Accept all</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookiesPopup;
