import React from "react";
import logo from "../../img/logo.png";
import "./Headerhome.css";
import { KeyboardArrowDown, Login } from "@mui/icons-material";
import BurgerMenu from "../../component/Burger";

function Headerhome() {
  return (
    <div className="Headerhome">
      <div className="Headerhome_logo">
        <a href="/">
          <img src={logo} alt="Le Cercle Français Logo" />
        </a>
      </div>

      <div className="Headerhome_left">
   
        <a
          href="/our_courses"
          style={{ color: "white", textDecoration: "none" }}
        >
          <p>Our courses</p>
        </a>

        <a
          href="/about_us"
          style={{ color: "white", textDecoration: "none" }}
        >
          <p>About</p>
        </a>

        <a
          href="/careers"
          style={{ color: "white", textDecoration: "none" }}
        >
          <p>Careers</p>
        </a>
      </div>
      <div className="Headerhome_right">
      
        <div className="Headerhome_login">
          <div className="Headerhome_icon_login">
            <Login />
          </div>
          <div className="Headerhome_login_text">
            <a href="/login">
              <p>Log In</p>
            </a>
          </div>
        </div>
      </div>
      <div className="Headerhome__burger">
        <BurgerMenu />
      </div>
    </div>
  );
}

export default Headerhome;
