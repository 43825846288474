import React, { useEffect, useRef } from "react";
import "./Payment.css";
import CallToAction100 from "../../component/CallToAction100";
import Razorpay from "../../img/Razorpay.png";
import { Lock } from "@mui/icons-material";

function Payment({
  userInfo,
  nextStep,
  price,
  description,
  durations,
  sessions,
  coursename,

}) {
  const fees = 0; // frais supplémentaires

  // S'assurer que price est un nombre avant d'effectuer l'addition
  const numericPrice = Number(price); // Conversion explicite en nombre
  const numericFees = Number(fees); // Conversion explicite en nombre

  // Calcul du total en ajoutant le prix du cours et les frais
  const total = numericPrice + numericFees; // Addition correcte

  const handlePayment = () => {
    if (!numericPrice) {
      alert("No price available for payment.");
      return;
    }

    const options = {
      key: "rzp_live_5cOX0x2XBHJiqf",
      amount: total * 100, // amount en petites unités de monnaie (par exemple, paise pour INR)
      currency: "INR",
      name: "Le Cercle Français",
      description: "Membership Fee",
      image: "https://your-logo-url.com/logo.png",
      handler: function (response) {
        alert(`Payment successful with ID: ${response.razorpay_payment_id}`);
        nextStep(); // Passer à l'étape suivante après un paiement réussi
      },
      prefill: {
        name: `${userInfo.name} ${userInfo.surname}`,
        email: userInfo.email,
        contact: userInfo.phone,
      },
      notes: {
        address: "Le Cercle Français Headquarters",
      },
      theme: {
        color: "#F37254",
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="Payment" ref={scrollRef}>
      <div className="Payment__block">
        <div className="Payment__left">
          <h2>Your order</h2>
          <div className="Payment__course">
            <h3>
              {coursename} - {durations}{" "}
            </h3>

            <p>{description}</p>

            <p>Online Sessions: {sessions}</p>

            <b>Total : ₹{numericPrice}</b>
          </div>
          <div className="h16"></div>
          <div className="Payment__fees">
            <h3>Fees</h3>
            <p>
              This is registration fees, for one year, access to the platform
              and course material
            </p>
            <b>
              <p>Total: ₹{numericFees}</p>
            </b>
          </div>
          <div className="h16"></div>
        </div>
        <div className="Payment__right">
          <div className="Payment__total">
            <h2>Payment</h2>
            <div className="Payment_line">
              <b>
                <p>Total amount</p>
              </b>
              <div className="Payment_line__total">
                <b>
                  <p>₹{total}</p>
                </b>
              </div>
            </div>
          </div>

          <CallToAction100 onClick={handlePayment} label="Pay with Razorpay" />
          <div className="Payment__img">
            <img src={Razorpay} alt="Razorpay Logo" />
          </div>

          <div className="Payment__secure">
            <Lock className="Payment__icon" />
            <p>Payment secured by Razorpay</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
