import React, { useEffect, useRef, useState } from "react";
import Headerhome from "../../Sections/HomeComponents/Headerhome";
import Footer from "../../Sections/Footer";
import { useHistory } from "react-router-dom";
import "./Enroll.css";
import Badge from "../../component/Badge";
import { CheckCircle } from "@mui/icons-material";
import CallToAction100 from "../../component/CallToAction100";
import Dropdown from "../../component/Dropdown";
import foot from "../../img/foot.png";

function Enrollyoungs({
  price_youngs,
  description_youngs,
  ages_youngs,
  durations_youngs,
  sessions_youngs,
  participants_youngs,
  coursename_youngs,
}) {
  const history = useHistory();
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const scrollRef = useRef(null);

  const handlePayment = () => {
    history.push({
      pathname: "/Enroll",
      state: {
        price: price_youngs,
        description: description_youngs,
        ages: ages_youngs,
        durations: durations_youngs,
        sessions: sessions_youngs,
        participants: participants_youngs,
        coursename: coursename_youngs,
      },
    });

    window.location.reload();
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = scrollRef.current.scrollTop;

      if (currentScrollY < 900) {
        setIsButtonVisible(true);
      } else {
        setIsButtonVisible(false);
      }
    };

    const scrollElement = scrollRef.current;
    scrollElement.addEventListener("scroll", handleScroll);

    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="Enrollyoungs"
      ref={scrollRef}
      style={{ height: "100vh", overflowY: "scroll" }}
    >
      <Headerhome />

      <div className="Enroll__layout">
        <div className={`Sticky__button ${isButtonVisible ? "visible" : ""}`}>
          <CallToAction100
            className="Sticky__button__align"
            label="Register"
            bg="var(--primary)"
            onClick={handlePayment}
          />
        </div>

        <div className="Margin__global">
          <div className="Enroll__blocks">
            <div className="Enroll__block__left">
              <div className="Enroll__headingimg">
                <div className="Enroll__img">
                  <img src={foot} alt="" />
                </div>

                <div className="Enroll__title__desc">
                  <br />
                  <h2>{coursename_youngs}</h2>
                  <p>{description_youngs}</p>
                </div>
              </div>

              <div className="h4"></div>
              <h3>Course Objectives</h3>
              <div className="Enroll__objectives">
                <div className="Enroll__objectives__icon">
                  <CheckCircle />
                </div>
                <p>...</p>
              </div>
              <div className="Enroll__objectives">
                <div className="Enroll__objectives__icon">
                  <CheckCircle />
                </div>
                <p>...</p>
              </div>
              <div className="Enroll__objectives">
                <div className="Enroll__objectives__icon">
                  <CheckCircle />
                </div>
                <p>...</p>
              </div>
              <div className="Enroll__objectives">
                <div className="Enroll__objectives__icon">
                  <CheckCircle />
                </div>
                <p>...</p>
              </div>

              <p>
                Embark on a linguistic adventure with Le Cercle Français. Our
                youngs program is the perfect start to a lifelong journey of
                language learning and cultural exploration. Enroll today and
                watch your child develop a love for French while gaining
                valuable communication skills.
              </p>
            </div>
            <div className="Enroll__block__right">
              <br />
              <h3>Become a student</h3>
              <p>
                <b>Dates:</b> {durations_youngs}
              </p>
              <p>
                <b>Price:</b> INR {price_youngs}
              </p>
              <p>
                <b>Sessions:</b> {sessions_youngs}
              </p>
              <div className="Enroll__participants">
                <div className="Enroll__participants__txt">
                  <p>
                    <b>Seats left:</b>
                  </p>
                </div>

                <div className="Enroll__participants__Badge">
                  <Badge label={participants_youngs} />
                </div>
              </div>

              <div className="h16"></div>
              <CallToAction100
                label="Register"
                bg="var(--primary)"
                onClick={handlePayment}
              />
            </div>
          </div>

          <div className="h120"></div>
          <div className="h120"></div>
          <div className="h120"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Enrollyoungs;
