import React, { useState } from "react";
import "./Home.css";
import Footer from "../Sections/Footer";

import ContactZone from "../Sections/HomeComponents/ContactZone";
import Headerhome from "../Sections/HomeComponents/Headerhome";
import Adbanner from "../Sections/HomeComponents/Adbanner";
import Homelanding from "../Sections/HomeComponents/Homelanding";
import Coursescard from "../Sections/HomeComponents/Coursescard";
import Tagline from "../Sections/HomeComponents/Tagline";
import Tagline2 from "../Sections/HomeComponents/Tagline2";
import Frenchtest from "../Sections/HomeComponents/Frenchtest";
import FreeTrial from "../component/FreeTrial";

function Home({
  coursename_pioupiou,
  participants_pioupiou,
  coursename_kids,
  participants_kids,
  coursename_youngs,
  participants_youngs,
  ages_pioupiou,
  ages_kids,
  ages_youngs,
}) {
  const [showAdBanner, setShowAdbanner] = useState(true);

  const handleCloseAdbanner = () => {
    setShowAdbanner(false);
  };
  return (
    <div className="Home">
      <div className="Home__content">
        {showAdBanner && <Adbanner onClose={handleCloseAdbanner} />}
        <FreeTrial />
        <Headerhome />
        <Homelanding />
        <Coursescard
          coursename_pioupiou={coursename_pioupiou}
          participants_pioupiou={participants_pioupiou}
          ages_pioupiou={ages_pioupiou}
          coursename_kids={coursename_kids}
          ages_kids={ages_kids}
          participants_kids={participants_kids}
          coursename_youngs={coursename_youngs}
          ages_youngs={ages_youngs}
          participants_youngs={participants_youngs}
        />
        <Tagline />
        <Tagline2 />
        <Frenchtest />
  
        <Footer />
      </div>
    </div>
  );
}

export default Home;
