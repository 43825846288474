import React, { useState, useEffect, useContext } from "react";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Privacypolicy from "./Pages/Privacypolicy";
import CookiesPopup from "./component/CookiesPopup";
import { CookieConsentProvider } from "./CookieConsentContext";
import Cookiepolicy from "./Pages/Cookiepolicy";
import Frenchquizlanding from "./Sections/Frenchquiz/Frenchquizlanding";
import Frenchquiz from "./Sections/Frenchquiz/Frenchquiz";
import Ourcourses from "./Sections/Ourcourses/Ourcourses";
import Aboutus from "./Pages/Aboutus";
import Careers from "./Pages/Careers";
import EnrollAdults from "./Pages/Courses/EnrollAdults";
import EnrollPiouPiou from "./Pages/Courses/EnrollPiouPiou";
import EnrollKids from "./Pages/Courses/EnrollKids";
import EnrollYoungs from "./Pages/Courses/EnrollYoungs";
import EnrollTuition from "./Pages/Courses/EnrollTuition";
import EnrollProfessional from "./Pages/Courses/EnrollProfessional";
import TermsAndConditions from "./Pages/TermsAndConditions";
import Cancellation from "./Pages/Cancellation";
import ContactUs from "./Pages/ContactUs";
import Shipping from "./Pages/Shipping";
import Dashboard from "./portal/PortalContent/Dashboard";
import Login from "./portal/Login";
import PrivateRoute from "./PrivateRoute";
import { AuthContext, AuthProvider } from "./AuthContext";
import EmptyPlaceholder from "./EmptyPlaceholder";
import {
  bach,
  description_pioupiou,
  ages_pioupiou,
  durations_pioupiou,
  sessions_pioupiou,
  participants_pioupiou,
  price_pioupiou,
  coursename_pioupiou,
  description_kids,
  ages_kids,
  durations_kids,
  sessions_kids,
  participants_kids,
  coursename_kids,
  price_kids,
  description_youngs,
  ages_youngs,
  durations_youngs,
  sessions_youngs,
  participants_youngs,
  price_youngs,
  coursename_youngs,
  description_tuitions,
  ages_tuitions,
  durations_tuitions,
  sessions_tuitions,
  participants_tuitions,
  price_tuitions,
  coursename_tuitions,
  description_adults,
  ages_adults,
  durations_adults,
  sessions_adults,
  participants_adults,
  price_adults,
  coursename_adults,
  description_pros,
  ages_pros,
  durations_pros,
  sessions_pros,
  participants_pros,
  price_pros,
  coursename_pros,
} from "./Data";
import Enroll from "./Pages/Courses/Enroll";
import Test from "./Test";
import FreeTrialBook from "./Pages/FreeTrialBook";

function App() {
  const { loading } = useContext(AuthContext);

  if (loading) {
    return <EmptyPlaceholder />;
  }
  return (
    <div className="App">
      <>
        <CookieConsentProvider>
          <CookiesPopup />
          <Router>
            <Switch>
              <Route exact path="/">
                <Home
                  coursename_pioupiou={coursename_pioupiou}
                  participants_pioupiou={participants_pioupiou}
                  ages_pioupiou={ages_pioupiou}
                  coursename_kids={coursename_kids}
                  ages_kids={ages_kids}
                  participants_kids={participants_kids}
                  coursename_youngs={coursename_youngs}
                  ages_youngs={ages_youngs}
                  participants_youngs={participants_youngs}
                />
              </Route>

              <Route exact path="/free_trial">
                <FreeTrialBook />
              </Route>
              <Route exact path="/privacy">
                <Privacypolicy />
              </Route>
              <Route exact path="/cookies">
                <Cookiepolicy />
              </Route>
              <Route exact path="/Terms_and_conditions">
                <TermsAndConditions />
              </Route>
              <Route exact path="/Cancellation_and_refund">
                <Cancellation />
              </Route>
              <Route exact path="/Shipping_and_delivery">
                <Shipping />
              </Route>
              <Route exact path="/Contact_us">
                <ContactUs />
              </Route>

              <Route exact path="/Test">
                <Test />
              </Route>
              <Route
                exact
                path="/Evaluate_your_french_level_Le_Cercle_Francais"
              >
                <Frenchquizlanding />
              </Route>
              <Route
                exact
                path="/Evaluate_your_french_level_Le_Cercle_Francais_Quizz"
              >
                <Frenchquiz />
              </Route>
              <Route exact path="/our_courses">
                <Ourcourses
                  bach={bach}
                  /* Pioupiou */
                  description_pioupiou={description_pioupiou}
                  ages_pioupiou={ages_pioupiou}
                  durations_pioupiou={durations_pioupiou}
                  sessions_pioupiou={sessions_pioupiou}
                  participants_pioupiou={participants_pioupiou}
                  price_pioupiou={price_pioupiou}
                  coursename_pioupiou={coursename_pioupiou}
                  /* kids */
                  description_kids={description_kids}
                  ages_kids={ages_kids}
                  durations_kids={durations_kids}
                  sessions_kids={sessions_kids}
                  participants_kids={participants_kids}
                  price_kids={price_kids}
                  coursename_kids={coursename_kids}
                  /* Youngs */
                  description_youngs={description_youngs}
                  ages_youngs={ages_youngs}
                  durations_youngs={durations_youngs}
                  sessions_youngs={sessions_youngs}
                  participants_youngs={participants_youngs}
                  price_youngs={price_youngs}
                  coursename_youngs={coursename_youngs}
                  /* Tuitions */
                  description_tuitions={description_tuitions}
                  ages_tuitions={ages_tuitions}
                  durations_tuitions={durations_tuitions}
                  sessions_tuitions={sessions_tuitions}
                  participants_tuitions={participants_tuitions}
                  price_tuitions={price_tuitions}
                  coursename_tuitions={coursename_tuitions}
                  /* Adults */
                  description_adults={description_adults}
                  ages_adults={ages_adults}
                  durations_adults={durations_adults}
                  sessions_adults={sessions_adults}
                  participants_adults={participants_adults}
                  price_adults={price_adults}
                  coursename_adults={coursename_adults}
                  /* Pros */
                  description_pros={description_pros}
                  ages_pros={ages_pros}
                  durations_pros={durations_pros}
                  sessions_pros={sessions_pros}
                  participants_pros={participants_pros}
                  price_pros={price_pros}
                  coursename_pros={coursename_pros}
                />
              </Route>

              <Route exact path="/about_us">
                <Aboutus />
              </Route>
              <Route exact path="/careers">
                <Careers />
              </Route>
              <Route exact path="/PiouPiou_Course">
                <EnrollPiouPiou
                  description_pioupiou={description_pioupiou}
                  ages_pioupiou={ages_pioupiou}
                  durations_pioupiou={durations_pioupiou}
                  sessions_pioupiou={sessions_pioupiou}
                  participants_pioupiou={participants_pioupiou}
                  price_pioupiou={price_pioupiou}
                  coursename_pioupiou={coursename_pioupiou}
                />
              </Route>
              <Route exact path="/Kids_Course">
                <EnrollKids
                  description_kids={description_kids}
                  ages_kids={ages_kids}
                  durations_kids={durations_kids}
                  sessions_kids={sessions_kids}
                  participants_kids={participants_kids}
                  price_kids={price_kids}
                  coursename_kids={coursename_kids}
                />
              </Route>
              <Route exact path="/Youngs_Course">
                <EnrollYoungs
                  description_youngs={description_youngs}
                  ages_youngs={ages_youngs}
                  durations_youngs={durations_youngs}
                  sessions_youngs={sessions_youngs}
                  participants_youngs={participants_youngs}
                  price_youngs={price_youngs}
                  coursename_youngs={coursename_youngs}
                />
              </Route>
              <Route exact path="/Tuitions_Course">
                <EnrollTuition
                  description_tuitions={description_tuitions}
                  ages_tuitions={ages_tuitions}
                  durations_tuitions={durations_tuitions}
                  sessions_tuitions={sessions_tuitions}
                  participants_tuitions={participants_tuitions}
                  price_tuitions={price_tuitions}
                  coursename_tuitions={coursename_tuitions}
                />
              </Route>
              <Route exact path="/Adults_Course">
                <EnrollAdults
                  description_adults={description_adults}
                  ages_adults={ages_adults}
                  durations_adults={durations_adults}
                  sessions_adults={sessions_adults}
                  participants_adults={participants_adults}
                  price_adults={price_adults}
                  coursename_adults={coursename_adults}
                />
              </Route>
              <Route exact path="/Professional_Course">
                <EnrollProfessional
                  description_pros={description_pros}
                  ages_pros={ages_pros}
                  durations_pros={durations_pros}
                  sessions_pros={sessions_pros}
                  participants_pros={participants_pros}
                  price_pros={price_pros}
                  coursename_pros={coursename_pros}
                />
              </Route>

              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/Enroll">
                <Enroll />
              </Route>

              <PrivateRoute exact path="/Dashboard" component={Dashboard} />
            </Switch>
          </Router>
        </CookieConsentProvider>
      </>
    </div>
  );
}

export default () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);
