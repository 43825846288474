import React from "react";
import Card from "../../component/Card";
import "./Coursecard.css";
import piou from "../../img/piou.png";
import fr from "../../img/fr.png";
import foot from "../../img/foot.png";

function Coursescard({
  coursename_pioupiou,
  participants_pioupiou,
  coursename_kids,
  participants_kids,
  coursename_youngs,
  participants_youngs,
  ages_pioupiou,
  ages_kids,
  ages_youngs,
}) {
  return (
    <div className="Coursecard">
      <div className="Coursecard__txt">
        <h2>Our French classes</h2>
      </div>

      <div className="Coursecard_Cards">
        <a href="/PiouPiou_Course">
          <Card
            image={piou}
            title={coursename_pioupiou}
            subtitle={ages_pioupiou}
            badge={participants_pioupiou}
          />
        </a>

        <div className="Card__Spacebetween"></div>
        <a href="/Kids_Course">
          <Card
            image={fr}
            title={coursename_kids}
            subtitle={ages_kids}
            badge={participants_kids}
          />
        </a>
        <div className="Card__Spacebetween"></div>
        <a href="/Youngs_Course">
          <Card
            image={foot}
            title={coursename_youngs}
            subtitle={ages_youngs}
            badge={participants_youngs}
          />
        </a>
      </div>
    </div>
  );
}

export default Coursescard;
