import { Rating } from "@mui/material";
import React from "react";
import "./CourseExplained.css";
import kidphoto from "../../img/kidphoto.jpg";

function CourseExplained({ bach }) {
  return (
    <div className="CourseExplained">
      <div className="CourseExplained__mobile">
        <img src={kidphoto} alt="" />
      </div>
      <div className="CourseExplained__left">
        <h2> Live French Classes Online {bach} </h2>
        <div className="CourseExplained__stars">
          <Rating name="read-only" value={5} readOnly />
          <p>5</p>
        </div>
        <div className="CourseExplained__blue">
          <p>238 students enrolled </p>
        </div>
        <div className="CourseExplained__left__txt">
          <p>
            Experience the joy of mastering French from the comfort of your home
            with Le Cercle Français. Our live, online classes are led by
            globally recognized language experts, offering an interactive and
            engaging way to achieve fluency in French.
          </p>
        </div>
      </div>
      <div className="CourseExplained__right">
        <img src={kidphoto} alt="" />
      </div>
    </div>
  );
}

export default CourseExplained;
