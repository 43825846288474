import React from "react";
import Headerhome from "../Sections/HomeComponents/Headerhome";
import Footer from "../Sections/Footer";

function Careers() {
  return (
    <div className="Careers">
      <Headerhome />
      <div className="Margin__global">
        <h2>Join Our Team</h2>
        <p>
          At Le Cercle Français, we are passionate about fostering a love for
          the French language and culture. We are always on the lookout for
          talented and dedicated individuals to join our team and help us in our
          mission to provide exceptional language education.
        </p>

        <h2>Why Work With Us?</h2>
        <p>As part of our team, you will have the opportunity to:</p>
        <ul>
          <li>Engage with a diverse and dynamic group of students.</li>
          <li>Work in a supportive and collaborative environment.</li>
          <li>
            Develop and enhance your teaching skills with ongoing professional
            development.
          </li>
          <li>Contribute to innovative educational programs.</li>
          <li>
            Be part of a community that values cultural exchange and lifelong
            learning.
          </li>
        </ul>

        <h2>Current Openings</h2>
        <p>
          We are currently seeking qualified candidates for the following
          positions:
        </p>
        <ul>
          <li>
            <strong>French Language Teacher:</strong> Inspire and educate
            students of all ages with engaging lessons and a passion for the
            French language.
          </li>
          <li>
            <strong>Curriculum Developer:</strong> Design and develop
            comprehensive language programs that cater to various proficiency
            levels.
          </li>
          <li>
            <strong>Administrative Coordinator:</strong> Provide essential
            support to ensure the smooth operation of our educational programs.
          </li>
        </ul>

        <h2>How to Apply</h2>
        <p>
          If you are interested in joining our team, please send your resume and
          a cover letter to{" "}
          <a href="mailto:careers@lecerclefrancais.com">
            careers@lecerclefrancais.com
          </a>
          . We look forward to hearing from you!
        </p>

        <h2>Contact Us</h2>
        <p>
          For more information about career opportunities at Le Cercle Français,
          please contact us at{" "}
          <a href="mailto:info@lecerclefrancais.com">
            info@lecerclefrancais.com
          </a>{" "}
          or call us at +91 87880 97983.
        </p>
        <div className="h64"></div>
      </div>

      <Footer />
    </div>
  );
}

export default Careers;
