import React from "react";
import "./Frenchquizlanding.css";
import CallToAction from "../../component/CallToAction";
import testfr from "../../img/testfr.png";
import Footer from "../Footer";
import Headerhome from "../HomeComponents/Headerhome";

function Frenchquizlanding({ onStart }) {
  return (
    <div className="Frenchquizlanding">
      <Headerhome />
      <div className="Frenchquizlanding__img">
        <img src={testfr} alt="" />
      </div>
      <div className="Frenchquizcontent">
        <h1>French test</h1>
        <div className="txt">
          Evaluate your child's level of French in a few minutes with our quick
          online test!
        </div>
        <div className="h24"></div>
        <a href="/Evaluate_your_french_level_Le_Cercle_Francais_Quizz">
          <CallToAction label="Démarrer le test" bg="#ff6b00" />
        </a>
        <div className="h64"></div>
        <div className="txt">
          Give your child the opportunity to measure their skills in French with
          our online test. In just 10 to 15 minutes, it will be able to assess
          their comprehension, grammar and vocabulary.
        </div>
        <h1>Why take this French test?</h1>
        <div className="txt">
          Quick and easy: An accessible format that allows your child to test
          yourself independently. Accurate assessment: Find out where it stands
          in his learning of French, ideal for positioning him in our classes at
          the French Circle. Immediate results: Receive instantly a clear
          overview of your child's level and their language skills. Don't miss
          this chance to assess your child’s language skills. Start now now our
          online French test!
        </div>

        <div className="h32"></div>
        <a href="/Evaluate_your_french_level_Le_Cercle_Francais_Quizz">
          <CallToAction label="Passer le test" />
        </a>
        <div className="h64"></div>
      </div>
      <Footer />
    </div>
  );
}

export default Frenchquizlanding;
