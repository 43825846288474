import React from "react";
import Contact from "../../img/Contact.png";
import "./ContactZone.css";

import ContactComponent from "./ContactComponent";

function ContactZone() {
  return (
    <div className="V1Contact__div">
      <div className="Contactzone__heading">
        <p>Contact</p>
      </div>
      <div className="Contactzone__txt">
        <p>
          We are at your entire disposal to answer your questions on our courses
          and inscriptions
        </p>
        <br />
        <p>
          E-mail : <span className="spanned">contact@lecerclefrancais.in</span>
          <br />
          By phone : 91 87880 97983
        </p>
      </div>

      <ContactComponent />
    </div>
  );
}

export default ContactZone;
