import React from "react";
import Headerhome from "../Sections/HomeComponents/Headerhome";
import Footer from "../Sections/Footer";
import "./ContactUs.css"

function ContactUs() {
  return (
    <div className="ContactUs">
      <Headerhome />
      <div className="Margin__global">
        <h2>Contact us</h2>
        <p>
          {" "}
          We are at your entire disposal to answer your questions on our courses
          and inscriptions
        </p>
        <p>
          E-mail : <span className="spanned">contact@lecerclefrancais.in</span>
          <br />
          By phone : 91 87880 97983
        </p>
        <div className="h64"></div>
        <div className="h64"></div>
        <div className="h64"></div>
        <div className="h64"></div>

      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
