import React from "react";
import Headerhome from "../Sections/HomeComponents/Headerhome";
import Footer from "../Sections/Footer";
import tiger from "../img/tiger.png";
import "./Aboutus.css";
import CallToAction from "../component/CallToAction";

function Aboutus() {
  return (
    <div className="Aboutus">
      <Headerhome />
      <div className="Margin__global">
        <div className="Aboutus__img">
          <img src={tiger} alt="" />
        </div>

        <h2>Our approach</h2>
        <p>
          At Le Cercle Français, we are dedicated to enhancing your French
          language skills beyond the standard curriculum. Our mission is to make
          learning enjoyable while focusing on comprehensive language
          development. Our courses are meticulously designed to cater to various
          proficiency levels, ensuring each learner receives personalized
          instruction tailored to their specific needs and goals.
        </p>

        <h2>Tailored for Every Age Group</h2>
        <p>
          Our courses are meticulously structured to cater to children from
          toddlers (age 2) to pre-teens (age 10). Each program is thoughtfully
          designed to meet the developmental needs of growing children, ensuring
          a fun and effective learning journey at every stage of childhood.
        </p>

        <h2>Immersive French Courses</h2>
        <p>
          Specializing in French language education, we offer a holistic
          approach that immerses students in both the language and culture. Our
          courses go beyond the basics, providing interactive lessons and
          cultural exploration that inspire a genuine passion for French in
          every child.
        </p>
      </div>
      <div className="h32"></div>
      <div className="Aboutus__CTA">
        <a href="/Our_Courses_levels_Le_Cercle_Francais">
          <CallToAction label="View our courses" bg={"var(--dark)"} />
        </a>
      </div>

      <div className="Aboutus__content">
        <h1>Your child's growth is our commitment.</h1>
      </div>
      <Footer />
    </div>
  );
}

export default Aboutus;
