import React, { useState, useEffect } from "react";

function AccountCreated({
  userInfo,
  price,
  coursename,
}) {
  const [messageReponse, setMessageReponse] = useState(""); // Pour afficher la réponse

  const handleSendEmail = async () => {
    try {
      const response = await fetch(
        "https://us-central1-lcfr-28a14.cloudfunctions.net/sendPaymentSuccessEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: userInfo.email,
            name: userInfo.name,
            paymentAmount: price,
            coursename: coursename,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de l'envoi de l'email.");
      }

      const resultat = await response.text(); // Récupère le message de succès
      setMessageReponse(resultat); // Afficher la réponse dans l'UI
    } catch (erreur) {
      setMessageReponse(erreur.message); // Afficher l'erreur dans l'UI
    }
  };

  // Utilisation de useEffect pour envoyer l'email immédiatement au montage du composant
  useEffect(() => {
    handleSendEmail();
  }, []); // Le tableau vide [] signifie que l'effet s'exécute uniquement une fois lors du montage

  return (
    <div>
      <h2>Un email de confirmation vous a été envoyé</h2>
      {messageReponse && <p>{messageReponse}</p>}
    </div>
  );
}

export default AccountCreated;
