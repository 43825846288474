import React from "react";
import Headerhome from "../Sections/HomeComponents/Headerhome";
import Footer from "../Sections/Footer";

function TermsAndConditions() {
  return (
    <div className="TermsAndConditions">
      <Headerhome />
      <div className="Margin__global">
        <h2>Terms and Conditions</h2>

        <p>Last Updated: October 21, 2024</p>

        <p>
          Welcome to Le Cercle Français! 😊 These terms and conditions outline
          the rules and regulations for the use of our website and services. By
          accessing this website, we assume you accept these terms and
          conditions in full. Do not continue to use Le Cercle Français's
          website if you do not accept all of the terms and conditions stated on
          this page.
        </p>

        <h2>1. Definitions</h2>

        <p>
          <strong>1.1 "Le Cercle Français"</strong> refers to our website
          located at www.lecerclefrancais.in and the services we offer.
        </p>
        <p>
          <strong>1.2 "User", "You", and "Your"</strong> refer to you, the
          person accessing this website and accepting our terms and conditions.
        </p>
        <p>
          <strong>1.3 "Courses"</strong> refer to the educational French courses
          offered by Le Cercle Français.
        </p>

        <h2>2. Services and Pricing</h2>

        <p>We offer various French courses, including:</p>
        <ul>
          <li>Pioupiou (for very young children): ₹9,000</li>
          <li>Kids (for children): ₹11,000</li>
          <li>Youngs (for teenagers): ₹11,000</li>
          <li>Tuitions (individual classes): ₹1,500 per session</li>
          <li>Adults: ₹15,000</li>
          <li>Pros (for professionals): ₹12,000</li>
        </ul>
        <p>All prices are in Indian Rupees (INR).</p>
        <p>
          We reserve the right to change the prices at any time. Any changes
          will be updated on the website.
        </p>

        <h2>3. Registration and Payment</h2>

        <p>
          To enroll in any course, you need to register on our website.
          Registration requires providing personal information and agreeing to
          these terms and conditions.
        </p>
        <p>
          Payment for courses must be made in full at the time of registration
          unless otherwise specified.
        </p>
        <p>
          We use secure payment gateways to process payments. You are
          responsible for maintaining the confidentiality of your payment
          details.
        </p>

        <h2>4. Cancellation and Refund Policy</h2>

        <p>
          You may cancel your enrollment within 7 days of registration for a
          full refund, provided that the course has not commenced.
        </p>
        <p>No refunds will be provided after the course has started.</p>
        <p>
          We reserve the right to cancel any course. In such cases, you will
          receive a full refund.
        </p>

        <h2>5. User Conduct</h2>

        <p>
          You agree to use the website only for lawful purposes and in a manner
          that does not infringe the rights of, restrict or inhibit anyone
          else's use and enjoyment of the website.
        </p>
        <p>
          You must not use the website to transmit or post any material which is
          defamatory, offensive, or otherwise objectionable.
        </p>

        <h2>6. Intellectual Property</h2>

        <p>
          Unless otherwise stated, we own the intellectual property rights for
          all material on the website. All intellectual property rights are
          reserved.
        </p>
        <p>
          You may view and print pages from the website for personal use,
          subject to restrictions set in these terms and conditions.
        </p>

        <h2>7. Privacy Policy</h2>

        <p>
          We are committed to protecting your privacy. Please refer to our
          Privacy Policy for details on how we collect, use, and protect your
          personal information.
        </p>

        <h2>8. Limitation of Liability</h2>

        <p>
          Le Cercle Français will not be liable for any loss or damage of any
          nature arising out of or in connection with your use of the website or
          the courses offered.
        </p>

        <h2>9. Governing Law</h2>

        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of India. Any disputes relating to these terms and
          conditions will be subject to the exclusive jurisdiction of the courts
          of [City], India.
        </p>

        <h2>10. Contact Information</h2>

        <p>
          If you have any questions about these terms and conditions, please
          contact us at{" "}
          <a href="mailto:contact@lecerclefrancais.in">
            contact@lecerclefrancais.in
          </a>
          .
        </p>

        <p>
          By using our website, you hereby consent to our terms and conditions
          and agree to its terms. Thank you for choosing Le Cercle Français! 🇫🇷
        </p>
      </div>
      <div className="h120"></div>
      <Footer />
    </div>
  );
}

export default TermsAndConditions;
