import React from "react";
import "./Adbanner.css";
import { Close } from "@mui/icons-material";

function Adbanner({ onClose }) {
  return (
    <div className="Adbanne__container">
      <div className="Adbanner">
        <p>
          🇫🇷 Le Cercle Français Winter 2024 classes are now opened !{" "}
          <a href="/Our_Courses_levels_Le_Cercle_Francais">
            <span>Join us</span>
          </a>
        </p>
        <div className="Adbannericon">
          <Close onClick={onClose} />
        </div>
      </div>
    </div>
  );
}

export default Adbanner;
