import React from "react";
import "./Ourcourses.css";
import Headerhome from "../HomeComponents/Headerhome";
import Footer from "../Footer";
import Coursecardfull from "../HomeComponents/Coursecardfull";
import piou from "../../img/piou.png";
import fr from "../../img/fr.png";
import foot from "../../img/foot.png";
import Corporate from "../../img/Corporate.png";
import Tuitions from "../../img/Tuitions.png";
import CourseExplained from "./CourseExplained";
import Adultcourse from "../../img/Adultcourse.png";

function Ourcourses({
  bach,

  description_pioupiou,
  ages_pioupiou,
  durations_pioupiou,
  sessions_pioupiou,
  participants_pioupiou,
  price_pioupiou,
  coursename_pioupiou,

  description_kids,
  ages_kids,
  durations_kids,
  sessions_kids,
  participants_kids,
  price_kids,
  coursename_kids,

  description_youngs,
  ages_youngs,
  durations_youngs,
  sessions_youngs,
  participants_youngs,
  price_youngs,
  coursename_youngs,

  description_tuitions,
  ages_tuitions,
  durations_tuitions,
  sessions_tuitions,
  participants_tuitions,
  price_tuitions,
  coursename_tuitions,

  description_adults,
  ages_adults,
  durations_adults,
  sessions_adults,
  participants_adults,
  price_adults,
  coursename_adults,

  description_pros,
  ages_pros,
  durations_pros,
  sessions_pros,
  participants_pros,
  price_pros,
  coursename_pros

}) {
  return (
    <div className="Ourcourses">
      <Headerhome />
      <CourseExplained bach={bach} />
      <div className="Ourcourses__head">
        <h2>Kids French classes 🇫🇷</h2>
      </div>
      <a href="/PiouPiou_Course">
        <Coursecardfull
          coursetitle={`${coursename_pioupiou} ${bach}`}
          coursedescription={`${description_pioupiou}`}
          ages={`${ages_pioupiou}`}
          duration={`${durations_pioupiou}`}
          sessions={`${sessions_pioupiou}`}
          participants={`${participants_pioupiou}`}
          img={piou}
          price={`${price_pioupiou}`}
        />
      </a>
      <a href="/Kids_Course">
        <Coursecardfull
          coursetitle={`${coursename_kids} ${bach}`}
          coursedescription={`${description_kids}`}
          ages={`${ages_kids}`}
          duration={`${durations_kids}`}
          sessions={`${sessions_kids}`}
          participants={`${participants_kids}`}
          img={fr}
          price={`${price_kids}`}
        />
      </a>
      <a href="/Youngs_Course">
        <Coursecardfull
          coursetitle={`${coursename_youngs} ${bach}`}
          coursedescription={`${description_youngs}`}
          ages={`${ages_youngs}`}
          duration={`${durations_youngs}`}
          sessions={`${sessions_youngs}`}
          participants={`${participants_youngs}`}
          img={foot}
          price={`${price_youngs}`}
        />
      </a>
      <a href="/Tuitions_Course">
        <Coursecardfull
          coursetitle={`${coursename_tuitions} ${bach}`}
          coursedescription={`${description_tuitions}`}
          ages={`${ages_tuitions}`}
          duration={`${durations_tuitions}`}
          sessions={`${sessions_tuitions}`}
          participants={`${participants_tuitions}`}
          img={Tuitions}
          price={`${price_tuitions}`}
        />
      </a>
      {/* <div className="Ourcourses__head">
        <h2>Adults French classes 🇫🇷</h2>
      </div>
      <a href="/Adults_Course">
        <Coursecardfull
          coursetitle={`${coursename_adults} ${bach}`}
          coursedescription={`${description_adults}`}
          ages={`${ages_adults}`}
          duration={`${durations_adults}`}
          sessions={`${sessions_adults}`}
          participants={`${participants_adults}`}
          img={Adultcourse}
          price={`${price_adults}`}
        />
      </a>
      <a href="/Professional_Course">
        <Coursecardfull
          coursetitle={`${coursename_pros} ${bach}`}
          coursedescription={`${description_pros}`}
          ages={`${ages_pros}`}
          duration={`${durations_pros}`}
          sessions={`${sessions_pros}`}
          participants={`${participants_pros}`}
          img={Corporate}
          price={`${price_pros}`}
        />
      </a> */}
      <div className="h64"></div>
      <Footer />
    </div>
  );
}

export default Ourcourses;
