import React from "react";
import "./Badge.css";

function Badge(props) {
  return (
    <div className="Badge">
      <p>{props.label}</p>
    </div>
  );
}

export default Badge;
