import React from "react";
import Headerhome from "../Sections/HomeComponents/Headerhome";
import Footer from "../Sections/Footer";

function FreeTrialBook() {
  return <div className="FreeTrialBook">
    <Headerhome />
    <div className="Margin__global">
      <h2>Free class</h2>
    </div>
    <Footer />
  </div>;
}

export default FreeTrialBook;
