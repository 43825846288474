import React, { useEffect, useRef, useState } from "react";
import Headerhome from "../../Sections/HomeComponents/Headerhome";
import Footer from "../../Sections/Footer";
import { useHistory } from "react-router-dom";
import "./Enroll.css";
import Badge from "../../component/Badge";
import { CheckCircle } from "@mui/icons-material";
import CallToAction100 from "../../component/CallToAction100";
import Dropdown from "../../component/Dropdown";
import Adultcourse from "../../img/Adultcourse.png";
import DropdownMenu from "../../component/DropdownMenu";

function Enrolladults({
  price_adults,
  description_adults,
  ages_adults,
  durations_adults,
  sessions_adults,
  participants_adults,
  coursename_adults
}) {
  const history = useHistory();
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const scrollRef = useRef(null);

  const handlePayment = () => {
    history.push({
      pathname: "/Enroll",
      state: {
        price: price_adults,
        description: description_adults,
        ages: ages_adults,
        durations: durations_adults,
        sessions: sessions_adults,
        participants: participants_adults,
        coursename: coursename_adults,

      },
    });


    window.location.reload();
  };


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = scrollRef.current.scrollTop;

      if (currentScrollY < 900) {
        setIsButtonVisible(true);
      } else {
        setIsButtonVisible(false);
      }
    };

    const scrollElement = scrollRef.current;
    scrollElement.addEventListener("scroll", handleScroll);

    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

 
 

  const courseItems1 = [
    "Understanding the letters of the French alphabet",
    "Sounds of each letter",
    "Letter identification games",
  ];

  const courseItems2 = [
    "Numbers",
    "Months and days and hours",
    "Time and space games",
  ];

  const courseItems3 = [
    "Naming fruits",
    "Naming vegetables",
    "Cooking and kitchen vocabulary",
  ];

  const courseItems4 = [
    "Identifying basic colors",
    "Naming objects of different colors",
    "Drawing game",
  ];

  const courseItems5 = [
    "Naming sports",
    "Popular sports in France",
    
  ];

  const courseItems6 = [
    "The farm animals",
    "The jungle animals",
    "Pets",
  ];

  const courseItems7 = [
    "Naming different rooms in the house",
    "Describing the purpose of each room",
    "House furniture",
  ];

  const courseItems8 = [
    "Classroom-related vocabulary",
    "Common actions: Listen, Write, Read and Learn",
  ];

  const courseItems9 = [
    "Weather vocabulary",
    "Discussing daily weather conditions.",
    "Seasons of the year",
  ];

  const courseItems10 = [
    "Identifying family members",
    "Describing relationships",

  ];


  return (
    <div
    className="Enrolladults"
    ref={scrollRef}
    style={{ height: "100vh", overflowY: "scroll" }} 
  >
      <Headerhome />
      
      <div className="Enroll__layout">
        <div className={`Sticky__button ${isButtonVisible ? "visible" : ""}`}>
          <CallToAction100
            className="Sticky__button__align"
            label="Register"
            bg="var(--primary)"
            onClick={handlePayment} />
        </div>
  

    

        <div className="Margin__global">
          <div className="Enroll__blocks">
            <div className="Enroll__block__left">
              <div className="Enroll__headingimg">
                <div className="Enroll__img">
                  <img src={Adultcourse} alt="" />
                </div>

                <div className="Enroll__title__desc">
                  <br />
                  <h2>Adults</h2>
                  <p>{description_adults}</p>
                </div>
              </div>

              <div className="h4"></div>
              <h3>Course Objectives</h3>
              <div className="Enroll__objectives">
                <div className="Enroll__objectives__icon">
                  <CheckCircle />
                </div>
                <p>
                  ...
                </p>
              </div>
              <div className="Enroll__objectives">
                <div className="Enroll__objectives__icon">
                  <CheckCircle />
                </div>
                <p>
                  ...
                </p>
              </div>
              <div className="Enroll__objectives">
                <div className="Enroll__objectives__icon">
                  <CheckCircle />
                </div>
                <p>
                  ...
                </p>
              </div>
              <div className="Enroll__objectives">
                <div className="Enroll__objectives__icon">
                  <CheckCircle />
                </div>
                <p>
                  ...
                </p>
              </div>
              <div className="h32"></div>
              <h3>Engaging Syllabus</h3>
              <p>
                Our comprehensive syllabus ensures an engaging learning
                experience:
              </p>
              <br />
              <Dropdown title="1. ..." items={courseItems1} />
              <div className="h16"></div>
              <Dropdown title="2. ..." items={courseItems2} />
              <div className="h16"></div>
              <Dropdown title="3. ..." items={courseItems3} />
              <div className="h16"></div>
              <Dropdown title="4. ..." items={courseItems4} />
              <div className="h16"></div>
              <Dropdown title="5. ..." items={courseItems5} />
              <div className="h16"></div>
              <Dropdown title="6. ..." items={courseItems6} />
              <div className="h16"></div>
              <Dropdown title="7. ..." items={courseItems7} />
              <div className="h16"></div>
              <Dropdown title="8. ..." items={courseItems8} />
              <div className="h16"></div>
              <Dropdown title="9. ..." items={courseItems9} />
              <div className="h16"></div>
              <Dropdown title="10. ..." items={courseItems10} />
              <div className="h32"></div>
              <p>
                Embark on a linguistic adventure with Le Cercle Français. Our
                adults program is the perfect start to a lifelong journey of
                language learning and cultural exploration. Enroll today and
                watch your child develop a love for French while gaining
                valuable communication skills.
              </p>
            </div>
            <div className="Enroll__block__right">
              <br />
              <h3>Become a student</h3>
              <p>
                <b>Dates:</b> {durations_adults}
              </p>
              <p>
                <b>Price:</b> {price_adults} INR
              </p>
              <p>
                <b>Sessions:</b> {sessions_adults}
              </p>
              <div className="Enroll__participants">
                <div className="Enroll__participants__txt">
                  <p>
                    <b>Seats left:</b>
                  </p>
                </div>

                <div className="Enroll__participants__Badge">
                  <Badge label={participants_adults} />
                </div>
              </div>
         
              <div className="h16"></div>
              <CallToAction100 label="Register" bg="var(--primary)" onClick={handlePayment} />
            </div>
          </div>

          <div className="h32"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Enrolladults;
