import React from "react";
import "./Homelanding.css";
import CallToAction from "../../component/CallToAction";

function Homelanding() {
  return (
    <div className="Homelanding">
      <div className="Homelanding__paragraph">
        <h1> Start learning French now 🥐</h1>
      </div>

      <div className="Homelanding__cta">
        <a href="/Our_Courses_levels_Le_Cercle_Francais">
          <CallToAction label="View our courses" />
        </a>
      </div>
    </div>
  );
}

export default Homelanding;
